import React from "react";
import { Box, Popover, Typography } from "@mui/material";
import NotificationCard from "./NotificationCard";

const NotificationsDropdown: React.FC<{ anchorEl: HTMLElement | null; onClose: () => void }> = ({
  anchorEl,
  onClose,
}) => {
  const open = Boolean(anchorEl);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{ mt: 1 }}
    >
      <Box sx={{ width: 300, p: 2, bgcolor: "#f9f9f9", borderRadius: 1 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Notifications
        </Typography>
        <NotificationCard
          title="Sample message"
          message="Sample description"
          iconUrl="https://via.placeholder.com/40"
        />
        <NotificationCard
          title="Sample message"
          message="Sample description"
          iconUrl="https://via.placeholder.com/40"
        />
        <NotificationCard
          title="Sample message"
          message="Sample description"
          iconUrl="https://via.placeholder.com/40"
        />
        
      </Box>
    </Popover>
  );
};

export default NotificationsDropdown;
