import React from "react";
import { Box, Avatar, Typography, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

interface NotificationCardProps {
  title: string;
  message: string;
  iconUrl?: string;
}

const NotificationCard: React.FC<NotificationCardProps> = ({ title, message, iconUrl }) => {
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();
  const formattedTime = currentDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

  return (
    <Box
      display="flex"
      flexDirection="column"
      p={1}
      mb={1}
      sx={{
        borderRadius: 1,
        boxShadow: 1,
        backgroundColor: "white",
        "&:hover": { backgroundColor: "#f5f5f5" },
      }}
    >
      <Box display="flex" alignItems="center">
        <Avatar src={iconUrl} alt={title} sx={{ mr: 2, width: 40, height: 40 }} />
        <Box>
          <Typography variant="subtitle1" fontWeight="bold">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {message}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
        <Typography variant="caption" color="text.secondary">
          {formattedDate} {formattedTime}
        </Typography>
        <IconButton size="small">
          <MoreHorizIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default NotificationCard;
